html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 1em;
  height: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "controls content content content";
}

.controls {
  grid-area: controls;
  position: fixed;
  width: 25%;
}

.displayarea {
  margin: 1em;
  grid-area: content;
  text-align: center;
}

.displayarea ul {
  padding: 0;
  margin: 0.5em;
}

.displayarea ul li {
  padding: 0.25em;
  margin: 0;
}

.displayarea.list img,
.displayarea.list video {
  max-width: 50vw;
  max-height: 50vh;
}

.displayarea.grid img,
.displayarea.grid video {
  max-width: 275px;
  max-height: 275px;
}

.displayarea ul {
  list-style: none;
}

.displayarea .entry.dtops ul,
.displayarea .entry.stations ul,
.displayarea .entry.selfies ul,
.displayarea .entry.homescreens ul,
.displayarea .entry.handwritings ul,
.displayarea .entry.pets ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.searchParams {
  padding: 0.5em 0;
}

.searchParams input {
  vertical-align: middle;
}
